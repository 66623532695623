import React, {useState, useEffect} from "react"
import Img from 'gatsby-image'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { useSpring,useTransition, animated,config } from 'react-spring'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {useHomePageData} from '../hooks/useHomePageData'
import { FloatingTextCard} from './Card'
import FloatingCard  from './Card'

const useStyles = makeStyles(theme => ({
  text: {
    margin: 'auto',
    color: 'white',
    willChange: 'transform',
    fontWeight: '500',
    padding: theme.spacing(2),
  },
  title: {
    color: 'white',
    fontWeight: '500',
    maxWidth:640,
    margin: 'auto',
    paddingBottom: theme.spacing(2),
  },
  titleRight: {
    color: 'white',
    fontWeight: '500',
    maxWidth:640,
    margin: 'auto',
    textAlign: 'right',
    paddingBottom: theme.spacing(2),
  },
  imageText:{
    padding: theme.spacing(2),
    backgroundColor: 'rgba(5,5,5,0.8)',
    alignItems: 'center',
  },
  gridTextLeft:{
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      left: '-3%',
    },
    // transform: 'skew(-4deg, 0deg)'
  },
  gridImgLeft:{
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      left: '3%',
    },
  },
  gridTextRight:{
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      right: '-3%',
    },
    // transform: 'skew(6deg, 0deg)',
    textAlign: 'left'
  },
  gridImgRight:{
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      right: '3%',
    },
    
  }
  
}))

const NonStretchedImage = props => {
  let normalizedProps = props
  if (props.fluid && props.fluid.presentationWidth) {
    normalizedProps = {
      ...props,
      style: {
        ...(props.style || {}),
        // maxWidth: props.fluid.presentationWidth,

      },
    }
  }
  return <Img {...normalizedProps} />
}

const AnimatedNonStretchedImage = animated(NonStretchedImage)
const AnimatedPaper = animated(Paper)

const ImageSection = (props) => {  
  const { textAlignment, title, text, images } = props

  const classes = useStyles()

  const [index, set] = useState(0)

  const transitions = useTransition(images.edges[index], item => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  })

  useEffect(() => void setInterval(() => set(state => (state + 1) % images.edges.length), 5000), [])

  const left = useSpring({config: config.wobbly, delay: 1000,  percent: -8, from: {percent: 0}})
  const leftCal = (p) => `${p}%`

  return (
    <Grid id={props.id} container spacing={0} justify="center" alignItems="center" direction={textAlignment === 'left' ? 'row' : 'row-reverse'}>
      <Grid item xs={12} sm={8} md={5} lg={5} className={textAlignment === 'left' ? classes.gridImgLeft : classes.gridImgRight }>
        
        <Grid container spacing={0}>
          <Grid item xs={12} >
            <Typography variant='h2' color='textSecondary' className={textAlignment === 'left' ? classes.title : classes.titleRight}>
              {title}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FloatingCard spring={{x:60, y:100, scale: 1.06}}>
              {transitions.map(({ item, props, key }) => (
                  <AnimatedNonStretchedImage 
                  key={key} 
                  style={{
                    ...props, 
                    willChange: 'opacity', 
                    margin: '0 auto'
                  }} 
                  fluid={item.node.childImageSharp.fluid}/>
              ))}
            </FloatingCard>
          </Grid>

        </Grid>
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={4} style={{left: left.percent.interpolate(leftCal)}} className={textAlignment === 'left' ? classes.gridTextLeft : classes.gridTextRight}>
        
        <FloatingTextCard>
          {text}
        </FloatingTextCard>
        <FloatingCard>
        </FloatingCard>
      </Grid>
    </Grid>
  )
}

export{NonStretchedImage}
export default ImageSection
