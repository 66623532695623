import React, {useState} from 'react'
import { useSpring, animated } from 'react-spring'
import { makeStyles } from '@material-ui/core/styles'
import { Card, CardContent, CardActionArea } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'


const useStyles = makeStyles(theme => ({
  card: {
    background: 'white',
    borderRadius: '5px',
    boxShadow: '0px 10px 30px -5px rgba(0, 0, 0, 1)',
    transition: 'box-shadow 0.5s',
    textAlign: 'center',
    display: 'flex',
    // height: 300,
    margin: 10,
  },
  text: {
    color: 'black',
    willChange: 'transform',
    fontWeight: '500',
    background: 'transparent'
  }
}))

const AnimatedCard = animated(Card)
const AnimatedTypography = animated(Typography)

function FloatingCard(props) {
  const {title, children, href, style, spring={x:30, y:30, scale: 1.1}} = props
  const [springProps, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 }  }))
  const classes = useStyles()
  const [state, toggle] = useState(false)
  
  const {opacity} = useSpring({opacity: state ? 0 : 1  })
  const calc = (x, y) => [(y - window.innerHeight /2 ) / spring.y, -(x - window.innerWidth / 2) / spring.x, spring.scale]
  const trans = (x, y, s) => `perspective(1000px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

  return (
    <AnimatedCard
      className={classes.card}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{transform: springProps.xys.interpolate(trans), ...style}}
      >
      <CardActionArea href={href}>
        {
          title ?
            <AnimatedTypography
              variant='h4' 
              onMouseEnter={() => toggle(!state)} 
              onMouseLeave={() => toggle(!state)} 
              className={classes.text}
              style={{transform: springProps.xys.interpolate(trans), width: '100%'}}
            >
              {title}
            </AnimatedTypography>
          : null
        }
        {children}
      </CardActionArea>
    </AnimatedCard>
  )
}

function FloatingTextCard(props) {
  const {text, children} = props
  const [springProps, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 }  }))
  const classes = useStyles()
  const [state, toggle] = useState(false)
  
  const {opacity} = useSpring({opacity: state ? 1 : 0  })
  const calc = (x, y) => [(y - window.innerHeight / 2) / 30, -(x - window.innerWidth / 2) / 30, 1.1]
  const trans = (x, y, s) => `perspective(1000px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

  return (
    <AnimatedCard
      className={classes.card}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{transform: springProps.xys.interpolate(trans)}}
      >
      <CardContent>
        <AnimatedTypography
          variant='body1' 
          onMouseEnter={() => toggle(!state)} 
          onMouseLeave={() => toggle(!state)} 
          className={classes.text}
        >
          {children}
        </AnimatedTypography>
      </CardContent>
    </AnimatedCard>
  )
}

export { FloatingTextCard }
export default FloatingCard