import React, {useState} from 'react'
// @material-ui/core components

import { navigate } from 'gatsby-link'
// core components
import CustomInput from './Contact'
import Grid from '@material-ui/core/Grid'
import { Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  section: {
    position: 'relative',
    height: 'auto',
  },
  title: {
    textDecoration: 'none',
    color: 'white',
    fontWeight: '500',
    margin: 'auto',
    textAlign: 'center'
  },
  description: {
    textAlign: 'center',
    paddingRight: '15px',
    paddingLeft: '15px',
    color: 'white',
    fontWeight: '500',

  },
  textCenter: {
    textAlign: 'center',
  },
  textArea: {
    paddingRight: '15px',
    paddingLeft: '15px',
  },
}))

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}


const ContactSection = (props) => {
  const classes = useStyles()
  const [state, setState] = useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {    
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() =>  alert("Thanks for the message! "))
      .catch(error => alert("Oh no, an error occurred on submission. Much Sadness!"));
  }

  return(
    <form
      name="contact"
      method="post"
      action="/thanks/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
    <input type="hidden" name="form-name" value="contact" />
      <p hidden>
        <label>
          if you're a human don't fill this out 😉 : <input name="bot-field" onChange={handleChange} />
        </label>
      </p>
      
      <Typography variant="h2" id="Contact" className={classes.title}>
        Contact Us
      </Typography>
      <Typography variant="subtitle1" className={classes.description}>
        Tilyear provides consumers and businesses access to their
        augmented reality future.<br/> Leave a message below and we will reach out:
      </Typography>

      <Grid container justify="center" >
        <Grid item xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Your Name"
            id="name"
            white
            formControlProps={{
              fullWidth: true,
              className: classes.textArea,
            }}
            inputProps={{
              name: 'name',
              required: true,
              onChange: handleChange,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Your Email"
            id="email"
            white
            formControlProps={{
              fullWidth: true,
              className: classes.textArea,
            }}
            inputProps={{
              name: 'email',
              type: 'email',
              required: true,
              onChange: handleChange,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomInput
            labelText="Your Message"
            id="message"
            white
            formControlProps={{
              fullWidth: true,
              className: classes.textArea,
            }}
            inputProps={{
              multiline: true,
              rows: 5,
              name: 'message',
              onChange: handleChange,
            }}
          />
        </Grid>

        <Grid item xs={12}  style={{paddingTop: '16px'}} className={classes.textCenter}>
          <Button type="submit" variant="contained" color="secondary">
            Send Message
          </Button>
        </Grid>

      </Grid>
    </form>

  )
}

export default ContactSection