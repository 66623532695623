import React from "react"
import { Link, navigate } from "gatsby"
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import Logo from './Image'
import PropTypes from "prop-types"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
    width: theme.spacing(10),
    padding: '4px'
  },
  title: {
    fontWeight: 'bolder',
    flexGrow: 1
  },
  header: {
    paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  social:{
    fontSize: 32, 
    color: 'white'
  }
}));


const Header = ({ siteTitle }) => {
  const classes = useStyles()
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return(
    <Container className={classes.header} maxWidth='lg'>
      <AppBar position="relative" color="transparent" elevation={0} >
        <Toolbar>
          <div edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <Logo />
          </div>
          
          <Typography variant="h3" color="primary" className={classes.title}>
            { matches ? siteTitle : null}
          </Typography>

          <Button color='primary'> 
          <a href='https://www.facebook.com/Tilyear/'>
            <FacebookIcon className={classes.social}/>
          </a>
          </Button>

          <Button color="primary">
          <a href='https://twitter.com/Tilyear_Reality' >
            <TwitterIcon className={classes.social}/> 
            </a>
          </Button>

        </Toolbar>
      </AppBar>    
    </Container>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
