import { useStaticQuery, graphql } from "gatsby"

 export const useHomePageData = () => {
  const data = useStaticQuery(
    graphql`
      query {
        explore: allFile(filter:{relativePath:{regex:"/(.*IMG.*)/"}}) {
          edges {
            node {
              id
              childImageSharp {
                fluid(quality: 100, maxWidth: 480,) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
        }
        personal: allFile(filter:{relativePath:{regex:"/(.*create.*)/"}}) {
          edges {
            node {
              id
              childImageSharp {
                fluid(quality: 100, maxWidth: 480,) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
        }
        blog: allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { featuredpost: { eq: true } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 500, maxHeight: 300, quality: 100, cropFocus:CENTER) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    ` 
  )
  return data
}