import React, {useState, useRef} from "react"
import { navigate } from 'gatsby'
import { useSpring, animated } from 'react-spring'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Button from '@material-ui/core/Button'

import Layout from "../components/Layout"
import WaveBackground from '../components/WaveBackground'
import Header from '../components/Header'
import Card from '../components/Card'
import ImageSection from '../components/ImageSection'
import Contact from '../components/formSection'
import { useModelData } from '../hooks/useOctaModelData'
import { useHomePageData } from '../hooks/useHomePageData'
import Footer from '../components/Footer'
import {NonStretchedImage} from '../components/ImageSection'
import SEO from '../components/SEO'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    justifyContent: 'center', 
    alignItems: 'center',
    background: 'linear-gradient(0deg,#111111 40%, #081A44  70%, #9400FF  80%, #FE2C89 90%, #FFA94C 100%)',
    position: 'relative'
  },
  paper:{
      margin: theme.spacing(2),
      height: '80vh',
      backgroundColor: 'rgba(5,5,5,0)',
      alignItems: 'center',
      textAlign: 'center',
      paddingTop: '100px'
  },
  background: {    
      width: '100%',
      height: '100%',
      top: '0px',
      left: '0px',
      '-webkit-background-size': 'cover',
      '-moz-background-size': 'cover',
      '-o-background-size': 'cover',
      backgroundSize: 'cover',
      zIndex: -101,
      position: 'absolute'
  },
  text: {
    margin: 'auto',
    color: 'white',
    willChange: 'transform',
    fontWeight: '500',
    padding: theme.spacing(2),
  },
  actionButton:{
  },
  title: {
    color: 'white',
    fontWeight: '500',
    margin: 'auto',
    textAlign: 'center'
  },
  titleLeft: {
    color: 'black',
    fontWeight: '500',
    maxWidth:640,
    margin: 'auto',
    textAlign: 'right'
  },
  logo: {
    position:'absolute'
  },
  imageText:{
    padding: theme.spacing(2),
    backgroundColor: 'rgba(5,5,5,0.8)',
    alignItems: 'center',
  },
  gridTextLeft:{
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      left: '-8%',
    },
    transform: 'skew(-8deg, 0deg)'
  },
  gridImgLeft:{
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      left: '8%',
    },
  },
  gridTextRight:{
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      right: '-8%',
    },
    transform: 'skew(6deg, 0deg)'
  },
  gridImgRight:{
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      right: '8%',
    },
  }
}))

const AnimatedPaper = animated(Paper);
const AnimatedContainer = animated(Container)

const IndexPage = (props) => {
  const classes = useStyles();
  const [open, toggle] = useState(true)
  const data = useModelData()
  const images = useHomePageData()
  
  let config = {
    width: 500,
    height: 500,
    scale: 1 ,
    rotate: 0,
    translateX: 0,
    translateY: 0,
  }

  // calculate
  const circleClip = (p) => `circle(${p}% at 50% 12%)`
  // const trans = (transX, transY ,xRotation, s) => `translate(${transX},${transY}) rotate(${xRotation} 250 250) scale(${s})`
  // const opacity = useSpring({config: config.wobbly, from: {opacity: 1}, opacity: open ? 0 : 0,})
  // const svg = useSpring({ config: config.wobbly, from: { transform: [0,0,0,1] }, transform: open ? [config.translateX,config.translateX,180,config.scale] : [0,0,0,1]})


  const circelMask = useSpring({config: config.wobbly, delay: 1000, percent:open ? 200 : 20, from: {percent: 10}})
  const opacityWave = useSpring({ delay: 1000, opacity: 1, from: {opacity: 0}})
  
  const fakeText = 'Build your own personalized showroom of virtual objects to share with friends. 3D creators can upload their own models for others to discover. Users can walk into virtual rooms filled with things from animals to artwork to products and see them in their own space. This app is currently in development. Subscribe below for more information.'
  const explore = 'See a new side of the city of Santa Monica with Santa Monica Explore. With AR you can see important points of interest around the city - such as murals, restaurants, and bars. Santa Monica Explore gives you a virtual map of the area, provides directions, and allows you to share AR messages with locals and tourists'
  
  return (
    <Layout> 
      <SEO
        title="Home"
        keywords={[`Tilyear`,`Augmented`, `Reality`, `Technology`, `Explore`,`Augmented Reality`, `Santa Monica`, `Tourism`, `arcore`,`arkit`]}
      />
      <animated.div className={classes.root} style={{clipPath: circelMask.percent.interpolate(circleClip)}} >
        <Header siteTitle='Tilyear' />

        <animated.div className={classes.background} style={opacityWave}>
          <WaveBackground data={data}/>
        </animated.div>


        <AnimatedContainer maxWidth='lg' >
          <AnimatedPaper elevation={0} className={classes.paper} style={opacityWave} > 
            <Typography className={classes.text} variant='h1'>
              DISCOVER A NEW WORLD
            </Typography>


            <Button onClick={() => navigate('/#explore')} className={classes.actionButton}>
              <ExpandMoreIcon style={{ fontSize: 40, color: 'white' }}/>
            </Button>
          </AnimatedPaper>
        </AnimatedContainer>

        <Container maxWidth='lg' style={{position:'relative', paddingTop: '40px'}}>
          <ImageSection id='explore' images={images.explore} textAlignment='left' text={explore} title='Explore'/>
          <ImageSection images={images.personal} textAlignment='right' text={fakeText} title='Create' />
        </Container>

        <Container maxWidth='lg' style={{paddingTop: '42px'}}>
          <Typography variant='h2' className={classes.title}>
            Blog
          </Typography>
          <Grid container justify="center" >
            {images.blog.edges.map((item, i) => (
              <Grid key={i} item xs={12} sm={i == 2 ? 12 : 6} md={4}>
                <Card href={item.node.fields.slug} title={item.node.frontmatter.title} style={{height: 300}} spring={{x:30, y:30, scale: 1.1}}>
                <NonStretchedImage fluid={item.node.frontmatter.featuredimage.childImageSharp.fluid}/>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>

        <Container maxWidth='md' style={{paddingTop: '42px'}}>
          <Contact/>
          <Footer/>
        </Container>
        
      </animated.div>
    </Layout>
  )
}

export default IndexPage
